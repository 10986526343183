//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import v from "voca";
import { mapState, mapGetters } from "vuex";
import Breadcrumbs from "@/views/Dashboard/Breadcrumbs";
// import MenuChangeRole from "@/components/MenuChangeRole";
import HeaderUserInfo from "@/components/HeaderUserInfo";
import NavigationDrawer from "@/components/NavigationDrawer";
import HeaderNotification from "@/components/HeaderNotification";
export default {
  name: "DashboardHospital",
  components: {
    Breadcrumbs,
    NavigationDrawer,
    // MenuChangeRole,
    HeaderUserInfo,
    HeaderNotification
  },
  data: () => ({
    show: false
  }),
  mounted() {
    console.log("Is pair locked?", this.pair.isLocked);
    this.show = this.pair.isLocked;
  },
  computed: {
    ...mapGetters({
      pair: "substrate/wallet"
    }),
    ...mapState({
      isHospitalAccountExist: state => state.substrate.isHospitalAccountExist,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData
    }),
    isHospital() {
      return this.$route.path.indexOf("hospital") > 0;
    },
    isHospitalDashboard() {
      return this.$route.path === "/hospital" || this.$route.path === "/hospital/";
    },
    pageHeader() {
      return this.$route.meta.pageHeader ? this.$route.meta.pageHeader : v.titleCase(this.$route.name);
    }
  },
  watch: {
    lastEventData() {
      if (this.lastEventData != null) {
        this.$store.dispatch("substrate/addListNotification", {
          address: this.wallet.address,
          event: this.lastEventData,
          role: "hospital"
        });
      }
    }
  },
  methods: {
    toggle() {
      this.show = false;
    },
    goToDashboard() {
      this.$router.push({
        path: "/hospital"
      });
    }
  }
};